<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <div
          v-if="workers.length > 0"
          class="card card-custom gutter-b card-stretch"
        >
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("MENU.ALL_WORKERS") }}
                <span class="text-muted pt-2 font-size-sm d-block">{{
                  $t("LIST.LIST_OF_ALL_WORKERS_IN_DATABASE")
                }}</span>
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <div class="mr-2">
                <button
                  @click.prevent="exportXLSX"
                  type="button"
                  class="btn btn-light-primary font-weight-bolder"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="media/svg/icons/Files/Export.svg" />
                  </span>
                  Export to .xlsx
                </button>
              </div>
              <!--end::Dropdown-->

              <!--begin::Button-->
              <router-link
                to="/workers/new"
                class="btn btn-primary font-weight-bolder"
                ><span class="svg-icon svg-icon-md">
                  <inline-svg src="media/svg/icons/Communication/Group.svg" />
                </span>
                {{ $t("LIST.NEW_WORKER") }}</router-link
              >
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body pt-10">
            <div v-if="isWorkersLoaded" class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center mb-0"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-7">
                          <span
                            @click="orderWorkersByName"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': nameOrder.isActive }"
                            >
                              {{ $t("LIST.FULL_NAME") }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 130px">
                          <span
                            @click="orderWorkersByEmail"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': emailOrder.isActive }"
                              >{{ $t("GENERAL.EMAIL") }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': emailOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="emailOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 150px">
                          {{ $t("GENERAL.MOBILE") }}
                        </th>
                        <th style="min-width: 150px"></th>
                        <th style="min-width: 170px" class="pr-7 text-right">
                          {{ $t("MENU.ACTIONS") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(worker, key) in workers" :key="key">
                        <td
                          class="pl-0 py-4 pl-7"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-50 symbol-light mr-4">
                              <div class="symbol-label">
                                <img
                                  src="media/misc/blank-image.jpg"
                                  alt=""
                                  width="100%"
                                  style="
                                    border-radius: 0.475rem;
                                    border: 1px solid rgb(181 181 195 / 30%);
                                  "
                                />
                              </div>
                            </div>
                            <div>
                              <router-link
                                :to="'/workers/view/' + worker.id"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >{{ worker.firstName }}
                                {{ worker.lastName }}</router-link
                              >
                              <span class="text-muted font-weight-bold d-block"
                                >{{ $t("GENERAL.OIB") }}: {{ worker.oib }}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ worker.email }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ worker.mob }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }"></td>
                        <td
                          class="pr-7 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <a
                            href="#"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                            @click.prevent="showWorker(worker.id)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Visible.svg"
                                title="View"
                                aria-label="View"
                              />
                            </span>
                          </a>
                          <router-link
                            :to="'/workers/edit/' + worker.id"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Design/Edit.svg"
                                title="Edit"
                                aria-label="Edit"
                              />
                            </span>
                          </router-link>
                          <button
                            @click="removeWorker(worker.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12 text-center">
                <b-spinner variant="primary" type="grow"></b-spinner>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert alert-primary py-4" role="alert">
          {{ $t("LIST.NO_WORKERS_IN_DATABASE") }}
          <router-link
            to="/workers/new"
            style="
              color: #ffffff;
              float: right;
              font-weight: 600;
              text-decoration: underline;
            "
            >{{ $t("ADD.ADD_WORKERS") }}</router-link
          >
        </div>
      </div>
    </div>

    <b-modal id="showModal" hide-header hide-footer size="md" ref="showModal">
      <div v-if="isSelectedWorkerLoaded" class="text-left">
        <div class="mb-2">
          <strong>{{ $t("GENERAL.NAME") }}: </strong
          ><span>{{ selectedWorker.firstName }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.LAST_NAME") }}: </strong
          ><span>{{ selectedWorker.lastName }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.ADDRESS") }}:</strong
          ><span
            ><a
              :href="
                'https://www.google.com/maps/place/' + selectedWorker.address
              "
              target="_blank"
              >{{ selectedWorker.address }}</a
            ></span
          >
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.OIB") }}: </strong
          ><span>{{ selectedWorker.oib }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.EMAIL") }}: </strong
          ><span
            ><a :href="'mailto:' + selectedWorker.email">{{
              selectedWorker.email
            }}</a></span
          >
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.MOBILE") }}: </strong
          ><span
            ><a :href="'tel:' + selectedWorker.mob">{{
              selectedWorker.mob
            }}</a></span
          >
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.PASSPORT") }}: </strong
          ><span>{{ selectedWorker.passport }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.FATHERS_NAME") }}: </strong
          ><span>{{ selectedWorker.fathersName }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.MOTHERS_NAME") }}: </strong
          ><span>{{ selectedWorker.mothersName }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.WORK_PERMIT_EXPIRATION_DATE") }}: </strong
          ><span>{{ selectedWorker.workPermitDate | formatDate }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.MEDICAL_EXPIRATION_DATE") }}: </strong
          ><span>{{ selectedWorker.physicalExamDate | formatDate }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.WORK_SAFETY") }}: </strong
          ><span
            v-if="selectedWorker.workProtection"
            class="label label-light-success label-inline"
            >{{ $t("GENERAL.HAS") }}</span
          ><span v-else class="label label-light-danger label-inline">{{
            $t("GENERAL.DOESNT_HAVE")
          }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.FIRST_AID") }}: </strong
          ><span
            v-if="selectedWorker.firstAid"
            class="label label-light-success label-inline"
            >{{ $t("GENERAL.HAS") }}</span
          ><span v-else class="label label-light-danger label-inline">{{
            $t("GENERAL.DOESNT_HAVE")
          }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.GEDA") }}: </strong
          ><span
            v-if="selectedWorker.geda"
            class="label label-light-success label-inline"
            >{{ $t("GENERAL.HAS") }}</span
          ><span v-else class="label label-light-danger label-inline">{{
            $t("GENERAL.DOESNT_HAVE")
          }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.OVERTIME_RATE") }}: </strong
          ><span>{{ selectedWorker.overtimeHr }} €</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.WEEKEND_HOURLYRATE") }}: </strong
          ><span>{{ selectedWorker.weekendHr }} €</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.WAGE") }}: </strong
          ><span>{{ selectedWorker.wage }} €</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.NIGHT_HOURLYRATE") }}: </strong
          ><span>{{ selectedWorker.nightHr }} €</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.NOTE") }}: </strong
          ><span>{{ selectedWorker.note }}</span>
        </div>
        <div class="mb-2">
          <strong>{{ $t("GENERAL.TOOLS") }}: </strong
          ><span>{{ selectedWorker.tools }}</span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "ListWorkers",
  data() {
    return {
      workers: [],
      isWorkersLoaded: false,
      nameOrder: {
        query: "asc",
        isActive: false,
      },
      emailOrder: {
        query: "asc",
        isActive: false,
      },
      selectedWorker: null,
      isSelectedWorkerLoaded: false,
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ALL_WORKERS"), route: "/workers/list" },
    ]);

    ApiService.get("workers").then(({ data }) => {
      this.workers = data["hydra:member"];
      this.isWorkersLoaded = true;
    });
  },
  methods: {
    exportXLSX() {
      ApiService.get("workers/export").then(({ data }) => {
        if (data.status === "ok") {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("WORKERS.EXPORT_SUCCESS"),
          });
          window.open(data.downloadUrl, "_blank").focus();
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("WORKERS.EXPORT_ERR"),
          });
        }
      });
    },
    orderWorkersByName() {
      this.nameOrder.isActive = true;
      if (this.nameOrder.query === "asc") this.nameOrder.query = "desc";
      else this.nameOrder.query = "asc";

      this.isWorkersLoaded = false;
      ApiService.get(
        "/workers",
        `?order[firstName]=${this.nameOrder.query}`
      ).then(({ data }) => {
        this.workers = [];
        this.workers = [...data["hydra:member"]];
        this.isWorkersLoaded = true;
      });
    },
    orderWorkersByEmail() {
      this.emailOrder.isActive = true;
      if (this.emailOrder.query === "asc") this.emailOrder.query = "desc";
      else this.emailOrder.query = "asc";

      this.isWorkersLoaded = false;
      ApiService.get("/workers", `?order[email]=${this.emailOrder.query}`).then(
        ({ data }) => {
          this.workers = [];
          this.workers = [...data["hydra:member"]];
          this.isWorkersLoaded = true;
        }
      );
    },
    showWorker(id) {
      this.isSelectedWorkerLoaded = false;
      ApiService.get("workers/" + id).then(({ data }) => {
        this.selectedWorker = data;
        this.isSelectedWorkerLoaded = true;
        this.$refs["showModal"].show();
      });
    },
    removeWorker(id) {
      const confirmation = confirm(this.$t("WORKERS.DELETE_CONFIRMATION"));
      if (confirmation === false) return;

      ApiService.delete("workers/" + id)
        .then(({ status }) => {
          if (status === 204) {
            this.workers = this.workers.filter((product) => product.id !== id);
            this.$notify({
              group: "notification",
              type: "error",
              title: this.$t("WORKERS.DELETE_SUCCESS"),
            });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "notification",
            type: "error",
            title: error,
          });
        });
    },
  },
};
</script>
